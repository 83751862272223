const NEXT_PUBLIC_ACTIVE_EXPERIMENTS = process.env
    .NEXT_PUBLIC_ACTIVE_EXPERIMENTS
    ? process.env.NEXT_PUBLIC_ACTIVE_EXPERIMENTS.split(',')
    : [];

function initQuickLinksDesignAndPosition(onActivated) {
    var _paq = (window._paq = window._paq || []);
    _paq.push([
        'AbTesting::create',
        {
            name: 'QuickLinksDesignAndPosition',
            percentage: 100,
            includedTargets: [
                { attribute: 'url', inverted: '0', type: 'any', value: '' },
            ],
            excludedTargets: [],
            variations: [
                {
                    name: 'original',
                    activate: function () {},
                },
                {
                    name: 'MoveUp',
                    activate: function () {
                        onActivated(this.name);
                    },
                },
                {
                    name: 'MoveUpWithImages',
                    activate: function () {
                        onActivated(this.name);
                    },
                },
            ],
            trigger: function () {
                if (
                    !NEXT_PUBLIC_ACTIVE_EXPERIMENTS.includes(
                        'QuickLinksDesignAndPosition'
                    )
                ) {
                    return false;
                }
                return true;
            },
        },
    ]);
}

export {
    initQuickLinksDesignAndPosition,
};
