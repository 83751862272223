import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import styles from './HomePage.module.scss';

import StreamField from '../../components/StreamField';
import { initQuickLinksDesignAndPosition } from '../../utils/experiments';
import HeroSmall from '../../components/HeroSmall/HeroSmall';

class HomePage extends PureComponent {
    state = {
        quickLinksVariant: 'original',
        language: 'english',
    };

    static defaultProps = {
        title: '',
    };

    static propTypes = {
        title: PropTypes.string.isRequired,
        contentBlocks: PropTypes.array,
    };

    componentDidMount() {
        const language = window.location.origin.includes('visitstockholm.se') ? 'swedish' : 'english';
        this.setState({language})

        initQuickLinksDesignAndPosition((quickLinksVariant) =>
            this.setState({ quickLinksVariant })
        );
    }

    render() {
        const {hero, contentBlocks} = this.props;


        return (
            <div className={styles['HomePage']}>
                <div className={styles['HomePage__Section--Black']}>
                    <HeroSmall {...hero} />
                </div>

                {contentBlocks && contentBlocks.length > 0 && (
                    <StreamField
                        items={contentBlocks}
                        fromStartpage={true}
                        experiments={{
                            quickLinksVariant: this.state.quickLinksVariant,
                        }}
                    />
                )}
            </div>
        )
    }
}

export default basePageWrap(HomePage);
